import Vue from 'vue'

// 自定义全局的按钮防抖函数，一秒内点击多次只执行一次
Vue.directive('btnDebounce', {
    inserted(el, binding) {
      let timer = {}
      el.addEventListener('click', () => {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          binding.value()
        }, 1000)
      })
    }
  })